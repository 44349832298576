import Amplify, { API, Storage } from "aws-amplify";
import awsconfig from "@/aws-exports";

Amplify.configure({
  // OPTIONAL - if your API requires authentication
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: awsconfig.aws_cognito_identity_pool_id,
    // REQUIRED - Amazon Cognito Region
    region: awsconfig.aws_cognito_region,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: awsconfig.aws_user_pools_id,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: awsconfig.aws_user_pools_web_client_id
  },
  API: {
    endpoints: [
      {
        name: "OneAudit",
        endpoint: "https://cs-dev2-api.controlcase.com/qm",
        region: "us-east-1"
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: awsconfig.aws_user_files_s3_bucket, //REQUIRED -  Amazon S3 bucket name
      region: awsconfig.aws_user_files_s3_bucket_region //OPTIONAL -  Amazon service region
    }
  }
});

const CCApiService = {
  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    const headers = {
      "Content-Type": "application/json",
      "x-api-key": "zcCHU9pSLD15DKElZVNbJ4dBmbMFCVKj3qnPmMXY"
    };
    return headers;
  },

  /**
   * Send the GET HTTP request
   * @param apiName
   * @param path
   * @param myInit
   * @returns {*}
   */
  get(apiName, path, body = "", header = "") {
    body = body != "" ? body : {};
    header = header != "" ? header : this.setHeader();

    const myInit = {
      body: body,
      headers: header
    };

    return API.get(apiName, path, myInit);
  },

  /**
   * Set the POST HTTP request
   * @param apiName
   * @param path
   * @param myInit
   * @returns {*}
   */
  async post(apiName, path, body = "", header = "") {
    body = body != "" ? body : {};
    header = header != "" ? header : this.setHeader();

    const myInit = {
      body: body,
      headers: header
    };

    return await API.post(apiName, path, myInit);
  },

  /**
   * Send the UPDATE HTTP request
   * @param apiName
   * @param path
   * @param myInit
   * @returns {*}
   */
  put(apiName, path, body = "", header = "") {
    body = body != "" ? body : {};
    header = header != "" ? header : this.setHeader();

    const myInit = {
      body: body,
      headers: header
    };

    return API.put(apiName, path, myInit);
  },

  /**
   * Send the DELETE HTTP request
   * @param apiName
   * @param path
   * @param myInit
   * @returns {*}
   */
  delete(apiName, path, body = "", header = "") {
    body = body != "" ? body : {};
    header = header != "" ? header : this.setHeader();

    const myInit = {
      body: body,
      headers: header
    };

    return API.del(apiName, path, myInit);
  },

  query(apiName, path, body = "", header = "") {
    body = body != "" ? body : {};
    header = header != "" ? header : this.setHeader();

    const myInit = {
      body: body,
      headers: header
    };

    return API.get(apiName, path, myInit);
  },

  async patch(apiName, path, body = "", header = "") {
    body = body != "" ? body : {};
    header = header != "" ? header : this.setHeader();

    const myInit = {
      body: body,
      headers: header
    };

    return await API.patch(apiName, path, myInit);
  },

  /**
   * UPLOAD FILE IN S3
   * @param key
   * @param object
   * @param level
   * @param contentType
   * @returns {*}
   */
  uploadFile(key, object, level = "", contentType = "") {
    const config =
      level != "" && contentType != ""
        ? {
            level: level, // private, public, protected
            contentType: contentType //text/plain
          }
        : "";

    return Storage.put(
      key,
      object,
      // {
      //   progressCallback(progress) {
      //     const prog = parseInt((progress.loaded / progress.total) * 100);
      //     console.log(prog + "%");
      //   }
      // },
      config
    );
  },
  /**
   * Download FILE from S3
   * @param key
   * @param level
   * @param identityId
   * @returns {pre sign url}
   */
  downloadFile(key, level = "", identityId = "") {
    var config;
    if (level == "protected") {
      config = {
        level: level, // private, public, protected
        identityId: identityId
      };
    } else if (level == "private") {
      config = {
        level: level // private, public, protected
      };
    } else {
      config = {};
    }

    return Storage.get(key, config);
  },
  /**
   * Delete FILE from S3
   * @param key
   * @param level // private, public, protected
   * @returns
   */
  async deleteFile(key, level = "") {
    var config;

    if (level == "") {
      config = { level: level };
    } else {
      config = {};
    }

    return await Storage.remove(key, config);
  }
};

export default CCApiService;
